// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-paginated-gallery-template-js": () => import("/opt/build/repo/src/templates/paginatedGalleryTemplate.js" /* webpackChunkName: "component---src-templates-paginated-gallery-template-js" */),
  "component---src-templates-postcard-template-js": () => import("/opt/build/repo/src/templates/postcardTemplate.js" /* webpackChunkName: "component---src-templates-postcard-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

